import reqHeaderToEntriesObj from './reqHeaderToEntriesObj';

const getDeviceCharacteristics = (userAgent: string, deviceCharHeader: string) => {
  let deviceChar = reqHeaderToEntriesObj(deviceCharHeader, ';');

  if (Object.keys(deviceChar).length === 0) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      deviceChar = { is_mobile: 'true' };
    } else {
      deviceChar = { is_mobile: 'false' };
    }
  }

  return deviceChar;
};

export default getDeviceCharacteristics;
