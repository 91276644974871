import { gql } from '@apollo/client';

const CHILD_CARE_QUALITIES = gql`
  fragment ChildCareCaregiverQualities on ChildCareCaregiverQualities {
    comfortableWithPets
    doesNotSmoke
    ownTransportation
    certifiedNursingAssistant
    certifiedRegisterNurse
    certifiedTeacher
    childDevelopmentAssociate
    cprTrained
    crn
    doula
    earlyChildDevelopmentCoursework
    earlyChildhoodEducation
    firstAidTraining
    nafccCertified
    specialNeedsCare
    trustlineCertifiedCalifornia
  }
`;

const CHILD_CARE_SERVICES = gql`
  fragment ChildCareCaregiverServices on ChildCareCaregiverServices {
    carpooling
    craftAssistance
    errands
    groceryShopping
    laundryAssistance
    lightHousekeeping
    mealPreparation
    swimmingSupervision
    travel
  }
`;

const HOUSEKEEPER_QUALITIES = gql`
  fragment HousekeeperQualities on HousekeeperQualities {
    comfortableWithPets
    doesNotSmoke
    ownTransportation
    providesEquipment
    providesSupplies
  }
`;

const HOUSEKEEPER_SERVICES = gql`
  fragment HousekeeperServices on HousekeeperServices {
    atticCleaning
    basementCleaning
    bathroomCleaning
    cabinetCleaning
    carpetCleaning
    changingBedLinens
    dishes
    dusting
    furnitureTreatment
    generalRoomCleaning
    greenCleaning
    houseSitting
    kitchenCleaning
    laundry
    moveOutCleaning
    organization
    ovenCleaning
    packingUnpacking
    petWasteCleanup
    plantCare
    refrigeratorCleaning
    springSummerCleaning
    surfacePolishing
    vacuumingOrMopping
    wallWashing
    windowWashing
  }
`;

const SENIOR_CARE_QUALITIES = gql`
  fragment SeniorCareCaregiverQualities on SeniorCareCaregiverQualities {
    comfortableWithPets
    doesNotSmoke
    ownTransportation
    alzheimersOrDementiaExperience
    certifiedNursingAssistant
    cprTrained
    homeHealthAideExperience
    hospiceExperience
    registeredNurse
  }
`;

const SENIOR_CARE_SERVICES = gql`
  fragment SeniorCareCaregiverServices on SeniorCareCaregiverServices {
    bathing
    companionship
    dementia
    errands
    feeding
    heavyLifting
    helpStayingPhysicallyActive
    homeHealth
    homeModification
    hospiceServices
    lightHousekeeping
    liveInHomeCare
    mealPreparation
    medicalManagement
    medicalTransportation
    mobilityAssistance
    personalCare
    respiteCare
    specialNeeds
    specializedCare
    transportation
    visitingNurse
    visitingPhysician
  }
`;

const TUTORING_QUALITIES = gql`
  fragment TutoringQualities on TutoringQualities {
    isComfortableWithPets
    doesNotSmoke
    ownsTransportation
    helpsWithArt
    helpsWithBusiness
    helpsWithComputers
    helpsWithDance
    helpsWithEnglish
    helpsWithForeignLanguage
    helpsWithMath
    helpsWithMusicAndDrama
    helpsWithMusicalInstruments
    helpsWithScience
    helpsWithSpecialEducation
    helpsWithSportsAndFitness
    helpsWithTestPrep
  }
`;

const TUTORING_SERVICES = gql`
  fragment TutoringServices on TutoringServices {
    tutorsInCenter
    tutorsInStudentsHome
    tutorsInTeachersHome
    tutorsOnline
  }
`;

const PET_CARE_QUALITIES = gql`
  fragment PetCareQualities on PetCareQualities {
    doesNotSmoke
    ownsTransportation
    caresForAmphibians
    caresForBirds
    caresForCats
    caresForDogs
    caresForExoticPets
    caresForFarmAnimals
    caresForFish
    caresForHorses
    caresForMammals
    caresForOtherPets
    isBondedAndInsured
    isNappsCertified
    isRedCrossPetFirstAidCertified
  }
`;

const PET_CARE_SERVICES = gql`
  fragment PetCareServices on PetCareServices {
    administersMedicine
    boardsOvernight
    doesDailyFeeding
    doesHouseSitting
    doesPetSitting
    doesPetWalking
    groomsAnimals
    retrievesMail
    trainsDogs
    transportsPets
    watersPlants
  }
`;

const CAREGIGS_QUALITIES = gql`
  fragment CareGigsQualities on CareGigsQualities {
    doesNotSmoke
    isComfortableWithPets
    ownsTransportation
    deliversCarsForService
    doesHouseSitting
    doesTransportation
    dropsOffLaundry
    dropsOffPackages
    feedsFish
    handlesEventTickets
    handlesInvitations
    helpsAtParties
    helpsDuringInjury
    helpsPackOrUnpack
    plansParties
    retrievesMail
    takesOutTrash
    waitsForHomeServices
    watersPlants
    willGiftShopOrWrap
    willGroceryShop
    willReturnPurchases
    willScheduleMovers
  }
`;

const SPECIAL_NEEDS_QUALITIES = gql`
  fragment SpecialNeedsQualities on SpecialNeedsQualities {
    doesNotSmoke
    isComfortableWithPets
    ownsTransportation
    caresForInfants
    caresForYouth
    caresForTeens
    caresForAdults
    caresForSeniors
    caresForADD
    caresForADHD
    caresForAspergers
    caresForAsthma
    caresForAutism
    caresForAutismSpectrumDisorder
    caresForBlindness
    caresForCancer
    caresForCeliac
    caresForCentralAuditoryProcessingDisorder
    caresForCerebralPalsy
    caresForCognitiveDisabilities
    caresForCysticFibrosis
    caresForDeafness
    caresForDiabetes
    caresForDwarfism
    caresForDyslexia
    caresForDevelopmentalDelays
    caresForDownSyndrome
    caresForEpilepsy
    caresForFetalAlcoholSyndrome
    caresForFoodAllergies
    caresForFragileX
    caresForHeartDefects
    caresForHydrocephaly
    caresForMentalIllness
    caresForMobilityChallenges
    caresForMultipleSclerosis
    caresForMuscularDystrophy
    caresForObesity
    caresForOtherNeeds
    caresForPervasiveDevelopmentalDisorder
    caresForPolymicrogyria
    caresForPraderWilli
    caresForRettSyndrome
    caresForSeizureDisorder
    caresForSensoryIntegrationDisorder
    caresForSickKids
    caresForSpeechDelay
    caresForSpinalCordInjury
    caresForThyroidCondition
    caresForTouretteSyndrome
    hasCprTraining
    hasFirstAidTraining
    hasSpecialEducationCredentials
    isCertifiedNursingAssistant
    isEarlyChildhoodEducator
    isLicensedPracticingNurse
    isLicensedVocationalNurse
    isOccupationalTherapist
    isPhysicalTherapist
    isRegisteredNurse
    isSpeechTherapist
  }
`;

const SPECIAL_NEEDS_SERVICES = gql`
  fragment SpecialNeedsServices on SpecialNeedsServices {
    doesAmbulation
    doesAppliedBehaviorAnalysis
    doesBathing
    doesBedBaths
    doesBladderManagementAssistance
    doesBloodSugarTesting
    doesBowelManagementAssistance
    doesBrushingProtocol
    doesDressingAssistance
    doesEpiPenInjections
    doesErrands
    doesFeeding
    doesHousecleaning
    doesInsulinInjections
    doesLaundry
    doesLongDistanceTransportation
    doesMealPreparation
    doesMedicalTransportation
    doesPositiveBehavioralSupport
    doesRepositioning
    doesRespiratoryCare
    doesSeizureAttendance
    doesStimmingAttendance
    doesTransferring
    speaksSignLanguage
    worksWithAssistiveTechnology
    worksWithGTubes
  }
`;

const LISTING_PAGE_CHILD_CARE_CAREGIVER_QUALIFICATIONS = gql`
  fragment ChildCareCaregiverQualifications on ChildCareCaregiverQualities {
    certifiedNursingAssistant
    cprTrained
    earlyChildhoodEducation
    firstAidTraining
    specialNeedsCare
    trustlineCertifiedCalifornia
  }
`;

const LISTING_PAGE_SENIOR_CARE_CAREGIVER_QUALIFICATIONS = gql`
  fragment SeniorCareCaregiverQualifications on SeniorCareCaregiverQualities {
    alzheimersOrDementiaExperience
    certifiedNursingAssistant
    cprTrained
    homeHealthAideExperience
    licensedNurse
    medicalEquipmentExperience
    woundCare
  }
`;

const LISTING_PAGE_PET_CARE_CAREGIVER_QUALIFICATIONS = gql`
  fragment PetCareCaregiverQualifications on PetCareQualities {
    isBondedAndInsured
    isNappsCertified
    isPSAMemberAndIsInsured
    isRedCrossPetFirstAidCertified
  }
`;

const COMMON_CAREGIVER_LISTING_ATTRIBUTES = gql`
  fragment CommonCaregiverListingAttributes on CommonCaregiverProfile {
    availabilityFrequency
    bio {
      title
      experienceSummary
      aiAssistedBio
    }
    distanceWillingToTravel {
      unit
      value
    }
    id
    payRange {
      hourlyRateFrom {
        amount
        currencyCode
      }
      hourlyRateTo {
        amount
        currencyCode
      }
    }
  }
`;

const LISTING_PAGE_COMMON_CAREGIVER_PROFILES_FIELDS = gql`
  ${COMMON_CAREGIVER_LISTING_ATTRIBUTES}
  ${CHILD_CARE_SERVICES}
  ${PET_CARE_SERVICES}
  ${SENIOR_CARE_SERVICES}
  ${LISTING_PAGE_CHILD_CARE_CAREGIVER_QUALIFICATIONS}
  ${LISTING_PAGE_SENIOR_CARE_CAREGIVER_QUALIFICATIONS}
  ${LISTING_PAGE_PET_CARE_CAREGIVER_QUALIFICATIONS}
  ${HOUSEKEEPER_SERVICES}
  fragment ListingPageCommonCaregiverProfileFields on CaregiverProfiles {
    commonCaregiverProfile {
      ...CommonCaregiverListingAttributes
      qualities {
        ...ChildCareCaregiverQualifications
        ...SeniorCareCaregiverQualifications
        ...PetCareCaregiverQualifications
      }
      supportedServices {
        ...ChildCareCaregiverServices
        ...PetCareServices
        ...SeniorCareCaregiverServices
        ...HousekeeperServices
      }
    }
  }
`;

const COMMON_CAREGIVER_PROFILE_FIELDS = gql`
  ${CHILD_CARE_QUALITIES}
  ${CHILD_CARE_SERVICES}
  ${HOUSEKEEPER_QUALITIES}
  ${HOUSEKEEPER_SERVICES}
  ${SENIOR_CARE_QUALITIES}
  ${SENIOR_CARE_SERVICES}
  ${TUTORING_QUALITIES}
  ${TUTORING_SERVICES}
  ${PET_CARE_QUALITIES}
  ${PET_CARE_SERVICES}
  ${CAREGIGS_QUALITIES}
  ${SPECIAL_NEEDS_QUALITIES}
  ${SPECIAL_NEEDS_SERVICES}
  ${COMMON_CAREGIVER_LISTING_ATTRIBUTES}
  fragment CommonCaregiverProfileFields on CaregiverProfiles {
    commonCaregiverProfile {
      ...CommonCaregiverListingAttributes
      qualities @include(if: $profileDetailsPage) {
        ...SeniorCareCaregiverQualities @include(if: $isSeniorCare)
        ...ChildCareCaregiverQualities @include(if: $isChildCare)
        ...HousekeeperQualities @include(if: $isHousekeeping)
        ...TutoringQualities @include(if: $isTutoring)
        ...PetCareQualities @include(if: $isPetCare)
        ...CareGigsQualities @include(if: $isCareGigs)
        ...SpecialNeedsQualities @include(if: $isSpecialNeeds)
      }
      supportedServices @include(if: $profileDetailsPage) {
        ...SeniorCareCaregiverServices @include(if: $isSeniorCare)
        ...ChildCareCaregiverServices @include(if: $isChildCare)
        ...HousekeeperServices @include(if: $isHousekeeping)
        ...TutoringServices @include(if: $isTutoring)
        ...PetCareServices @include(if: $isPetCare)
        ...SpecialNeedsServices @include(if: $isSpecialNeeds)
      }
    }
  }
`;

const CAREGIVER_SEARCH_RESULT_FIELDS = gql`
  ${LISTING_PAGE_COMMON_CAREGIVER_PROFILES_FIELDS}
  fragment CaregiverSearchResultFields on SEOSegmentationSearchResults {
    pagination {
      startIndex
      endIndex
      totalNumberOfResults
      numberOfPages
      currentPage
    }
    caregiverSearchResults {
      profileURL
      latestReview {
        reviewDate
        rating
        comment
        reviewer {
          displayName
          imageURL
        }
      }
      caregiver {
        yearsOfExperience
        avgReviewRating
        hasCareCheck
        numberOfReviews
        responseTime
        hiredTimes
        hiredByCounts {
          locality {
            hiredCount
          }
        }
        profiles {
          ...ListingPageCommonCaregiverProfileFields
        }
        member {
          isPremium
          age
          imageURL
          displayName
          address {
            city
            state
          }
        }
      }
      caregiverReviews {
        comment
        rating
        reviewDate
        reviewer {
          displayName
        }
      }
    }
  }
`;

const PROVIDER_SEARCH_RESULT_FIELDS = gql`
  ${COMMON_CAREGIVER_LISTING_ATTRIBUTES}
  fragment ProviderSearchResultFields on SEOSegmentationSearchResults {
    pagination {
      startIndex
      endIndex
      totalNumberOfResults
      numberOfPages
      currentPage
    }
    providerSearchResults {
      seoSegmentationProvider {
        provider {
          address {
            addressLine1
            city
            state
            zip
          }
          name
          avgReviewRating
          description
          logo {
            urlThumbnail
          }
        }
        numberOfReviews
        providerRate {
          rate {
            amount
            currencyCode
          }
          rateDuration
        }
        costimate {
          rate {
            amount
            currencyCode
          }
          rateDuration
        }
      }
      latestReview {
        comment
        rating
        reviewDate
        reviewer {
          displayName
          imageURL
        }
      }
      profileURL
      providerReviews {
        comment
        rating
        reviewDate
        reviewer {
          displayName
        }
      }
    }
  }
`;

const JOB_SEARCH_RESULT_FIELDS = gql`
  fragment JobSearchResultFields on SEOSegmentationSearchResults {
    pagination {
      startIndex
      endIndex
      totalNumberOfResults
      numberOfPages
      currentPage
    }
    jobSearchResults {
      seoSegmentationJob {
        job {
          title
          startDate
          hourlyRateRange {
            hourlyRateTo {
              amount
              currencyCode
            }
            hourlyRateFrom {
              amount
              currencyCode
            }
          }
        }
        city
        state
        seekerAvatar
        description
        type
      }
      jobDetailURL
    }
  }
`;

const ARCHIVED_JOB_SEARCH_RESULT_FIELDS = gql`
  fragment ArchivedJobSearchResultFields on SEOSegmentationSearchResults {
    archivedJobSearchResults {
      seoSegmentationArchivedJob {
        description
        title
        type
      }
    }
  }
`;

export {
  JOB_SEARCH_RESULT_FIELDS,
  ARCHIVED_JOB_SEARCH_RESULT_FIELDS,
  CAREGIVER_SEARCH_RESULT_FIELDS,
  PROVIDER_SEARCH_RESULT_FIELDS,
  COMMON_CAREGIVER_PROFILE_FIELDS,
  COMMON_CAREGIVER_LISTING_ATTRIBUTES,
};
